<template>
  <v-img src="@/assets/banner.webp" class="pa-0"> </v-img>
</template>

<script>
export default {
  name: "openingImage",
};
</script>

<style></style>
