<template>
  <div>
    <profile />
  </div>
</template>

<script>
import Profile from "../components/Teams/Profile";

export default {
  components: { Profile },
};
</script>

<style></style>
