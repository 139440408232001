<template>
  <div>
    <h1>Our Team</h1>
    <p>
      We are a small team of talented university students with a wide range of
      skills and experience. We love educating youth about robotics, and we do
      it with passion. We look forward to working with you!
    </p>

    <h2>President</h2>

    <v-layout>
      <v-flex md12 sm12 xs12>
        <v-card class="mx-auto" justify="start" color="white" width="400px">
          <v-img
            src="@/assets/Team/Sophie.png"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Sophie Lin </v-card-title>
          <v-card-subtitle> President </v-card-subtitle>
        </v-card>
      </v-flex>
    </v-layout>

    <h2>Vps</h2>

    <v-layout row wrap>
      <v-flex md4 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Daniel.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Daniel Lui </v-card-title>
          <v-card-subtitle> VP Technology </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-flex md4 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Irene.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Irene Zhang </v-card-title>
          <v-card-subtitle> VP Marketing and Outreach </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-flex md4 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Clement.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Clement Halim </v-card-title>
          <v-card-subtitle> VP Programs and Education </v-card-subtitle>
        </v-card>
      </v-flex>
    </v-layout>
    <h2>Our Team</h2>

    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Ramit.png"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Ramit Kataria </v-card-title>
          <v-card-subtitle> Website Developer </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Ahasan.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Ahasan Bhuiyan </v-card-title>
          <v-card-subtitle> Electronics Specialist </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Wasif.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Wasif Sarker </v-card-title>
          <v-card-subtitle> Website Developer </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Vanessa.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Vanessa Quon </v-card-title>
          <v-card-subtitle> Curriculum Developer </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Elizabeth.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Elizabeth Fernandes </v-card-title>
          <v-card-subtitle> Marketing and Outreach </v-card-subtitle>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <h2>Mentors</h2>

    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Dwayne.png"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Dwayne Lemos </v-card-title>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Shivam.jpg"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Shivam Kataria </v-card-title>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Navraj.png"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Navraj Sandhu </v-card-title>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-card class="mx-auto" max-width="400">
          <v-img
            src="@/assets/Team/Kenny.png"
            height="400px"
            width="400px"
          ></v-img>

          <v-card-title> Kenny Niu </v-card-title>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </div>
</template>

<script>
require("../../assets/Team/Ramit.png");
export default {
  data: () => ({}),
};
</script>

<style>
h1 {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 50px;
  margin-left: 40px;
}
p {
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin-left: 40px;
}
h1 {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 50px;
  margin-left: 40px;
}
h2 {
  font-weight: bold;
  color: rgb(0, 0, 0);

  margin-left: 40px;
}
</style>
