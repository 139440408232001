<template>
  <div>
    <h1>Our Projects</h1>
    <h2>🚀 SRIL 2022 Planetary Exploration Challenge 🚀</h2>

    <p>
      On a distant planet, a rover awaits your command to begin exploration of a
      new planet's surface! SRIL's 2022 Planetary Exploration challenge hopes to
      explore some of the objectives, and challenges faced by space agencies
      when exploring the uncharted terrain of extraterrestrial bodies.
    </p>

    <p>
      SRIL hosted 3 workshops on space exploration, programming, and robotics,
      as well as a final robotics competition. Through the workshops,
      participants learn how to program a robot to navigate the Martian terrain
      in order to collect samples for research. SRIL built a custom robot arena
      that simulates the Martian environment. The participants did a great job
      programming their robot to navigate the Martian maze!

      <a
        target="_blank"
        href="https://www.youtube.com/watch?v=0ns-P3Y0aAA&list=PLZY4j3TBPfJ2sv7hl1YAZZvsCXzR2HNC0"
      >
        Watch it on Youtube!
      </a>
    </p>

    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2022/2022.1.jpg"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2022/2022.2.png"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2022/2022.3.jpg"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2022/2022.4.jpg"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <br />
    <h2>🚗 2021 SRIL Gravity Car Challenge 🚗</h2>

    <p>
      SRIL is proud to present the Gravity Car Challenge! Learn how to design
      and build a gravity car through our three workshops led by university STEM
      students, and put your newly learned skills to the test in our Final
      Gravity Car Race, where 20 team submissions will be 3D printed and
      competed on a racetrack. Register by yourself or with a team of friends to
      win $30 in gift cards for attending the workshops, with additional cash
      prizes available for the Final Challenge! We are also excited to host a
      workshop in August featuring a Co-op student working at Tesla, so be sure
      to attend and learn all about their experience.

      <br />
      <a target="_blank" href="/SRIL C2C Final Report 2021.pdf">
        View detailed project report
      </a>
      <br />
      <a
        target="_blank"
        href="https://www.youtube.com/watch?v=a-xmC_14WYA&list=PLZY4j3TBPfJ3mw0lRzM7itrHR3yNQ9dWU"
      >
        Watch it on Youtube!
      </a>
    </p>

    <center>
      <v-img
        src="@/assets/Projects/2021/Competition Winners-3.png"
        contain
        max-height="50vh"
      >
      </v-img>
    </center>

    <br />

    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2021/Competition Winners.png"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2021/Competition Winners-1.png"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img
          src="@/assets/Projects/2021/Competition Winners-2.png"
          contain
          max-height="40vh"
        >
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <br />
    <center>
      <v-img
        src="@/assets/Projects/2021/Tesla Speaker Feature.png"
        contain
        max-height="50vh"
      >
      </v-img>
    </center>

    <h2>2020 SRIL STEM Workshop Series</h2>

    <P>
      During the COVID-19 Pandemic, SRIL hosted 3 STEM Workshops led by UBC
      professors, alumni, and students. The workshops include UAV Mechatronics
      Engineering, 3D Printing & CAD, and Leadership in EDI. Over 200
      university, high school and elementary students attended these workshops.

      <br />

      <a target="_blank" href="/SRIL C2C Final Report 2020.pdf">
        View detailed project report
      </a>
      <br />
      <a
        target="_blank"
        href="https://www.youtube.com/watch?v=aGZRlvhvw8g&list=PLZY4j3TBPfJ2uwQiXK-iEl3CxvqgFDXBj"
      >
        Watch it on Youtube!
      </a>
    </P>

    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img src="@/assets/Projects/2020/1.png" contain max-height="40vh">
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img src="@/assets/Projects/2020/3.png" contain max-height="40vh">
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img src="@/assets/Projects/2020/5.png" contain max-height="40vh">
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <br />

    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img src="@/assets/Projects/2020/2.png" contain max-height="40vh">
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img src="@/assets/Projects/2020/4.png" contain max-height="40vh">
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md2 sm12 xs12>
        <v-img src="@/assets/Projects/2020/6.png" contain max-height="40vh">
        </v-img>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <br />

    <h2>UBC Geering Up x SRIL: Robotics Today (All-Girls Grade 9)</h2>

    <p>
      One of the best ways to find out what you are passionate about is to try
      it out for yourself! In this event, you will be able to see a robot you
      have created come to life in a fun and interactive environment. Robotics
      Today will feature how to program robots in a virtual VEX Robotics
      simulator. This event will help you discover the many ways in which
      robotics can help our world.
    </p>
    <center>
      <v-img
        src="@/assets/Projects/2020/geeringup.jpg"
        contain
        max-height="50vh"
      >
      </v-img>
    </center>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
